<template>

  <!-- Лента пользователя -->
  <v-container class="h-100">

    <div class="py-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">А кто это</h1>
        <h1 class="title is-1 black--text">у нас?</h1>
      </div>
    </div>
    <p class="title is-5">Не закрывайте окно
      <br>Получаем информацию о Вас для авторизации через <span style="color: #fc4e2f;">Яндекс</span></p>
  </v-container>

</template>

<script>
export default {
  name: 'YandexAuthorizationPage',
  data() {
    return {
    };
  },
  created() {
    YaSendSuggestToken(
      process.env.VUE_APP_HOST,
      {
        flag: true
      }
    )
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>
.block-content img {
  max-width: 100%;
  height: auto;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

</style>
